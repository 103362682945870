import ReactPlayer, { Config } from "react-player"
import "./video-player.css"
import { BaseReactPlayerProps } from "react-player/base"
import { ThumbImg } from "./video-player.util"
import { useMemo } from "react"

interface PlayerProps extends BaseReactPlayerProps {}
export interface IVideoPlayer {
  playerProps: PlayerProps
  noDownload?: boolean
  thumbImg?: {
    src?: string
    alt?: string
    className?: string
  }
}

const VideoPlayer: React.FC<IVideoPlayer> = ({
  playerProps,
  noDownload = false,
  ...props
}) => {
  const config: Config = {}
  const { thumbImg } = props

  // Create a gradient background as a fallback when video thumbnail is not available
  const defaultThumbnail = useMemo(() => (
    <div className="primary-gradient h-full w-full rounded-[var(--b-radius)] opacity-40" />
  ), [])

  if (noDownload) {
    config["file"] = {
      attributes: {
        controlsList: "nodownload",
      },
    }
  }

  return (
    <ReactPlayer
      light={thumbImg ? <ThumbImg {...thumbImg} /> : defaultThumbnail}
      onError={console.error}
      height={280}
      config={config}
      {...playerProps}
    />
  )
}

export default VideoPlayer
