export interface PlanFeature {
  name: string
  free: boolean
  basic: boolean
  premium: boolean
  grow: boolean
}

export interface Plan {
  name: string
  price: number // Monthly price
  yearlyPrice: number // Yearly price with discount applied
  interactions: number
  videos: number
  pricePerVideo: number
}

export const features: PlanFeature[] = [
  {
    name: "1 month non-renewable commercial license for generated video ads",
    free: true,
    basic: false,
    premium: false,
    grow: false,
  },
  {
    name: "Recurring monthly commercial license (up to your plan's video limit) as long as subscription is active",
    free: false,
    basic: true,
    premium: true,
    grow: true,
  },
  {
    name: "Powered by Lestok Logo",
    free: true,
    basic: false,
    premium: false,
    grow: false,
  },
  {
    name: "Chat-based video editor",
    free: false,
    basic: true,
    premium: true,
    grow: true,
  },
  {
    name: "Custom content upload",
    free: false,
    basic: false,
    premium: true,
    grow: true,
  },
  {
    name: "Multilanguage AI converter",
    free: false,
    basic: false,
    premium: true,
    grow: true,
  },
  {
    name: "Scaler - create multiple variations",
    free: false,
    basic: false,
    premium: false,
    grow: true,
  },
  {
    name: "Premium video library",
    free: false,
    basic: false,
    premium: true,
    grow: true,
  },
  {
    name: "ROAS Insights",
    free: false,
    basic: false,
    premium: false,
    grow: true,
  },
  {
    name: "API Connections",
    free: false,
    basic: false,
    premium: false,
    grow: true,
  },
]

export const plans: Plan[] = [
  {
    name: "Free",
    price: 0,
    yearlyPrice: 0, // No discount for free plan
    interactions: 20,
    videos: 20,
    pricePerVideo: 0,
  },
  {
    name: "Basic Package",
    price: 25,
    yearlyPrice: 225, // $25 * 12 * 0.75 (25% discount)
    interactions: 70,
    videos: 50,
    pricePerVideo: 0.5,
  },
  {
    name: "Premium Package",
    price: 49,
    yearlyPrice: 412, // $49 * 12 * 0.7 (30% discount)
    interactions: 300,
    videos: 150,
    pricePerVideo: 0.49,
  },
  {
    name: "Grow Package",
    price: 99,
    yearlyPrice: 832, // $99 * 12 * 0.7 (30% discount)
    interactions: 1000,
    videos: 450,
    pricePerVideo: 0.49,
  },
]

export const planLevels = {
  'free': 0,
  'basic': 1,
  'premium': 2,
  'grow': 3
} as const
