import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analyticsService from '../../services/analytics/analytics.service';
import gtmService from '../../services/analytics/gtm.service';

/**
 * Component to track route changes for analytics.
 * Place this component at the top level of your app to track all page views.
 */
const RouteTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Track page view on route change using Firebase Analytics
    analyticsService.trackPageView(location.pathname);
    
    // Track page view on route change using Google Tag Manager
    gtmService.trackPageView(location.pathname);
  }, [location]);
  
  // This component doesn't render anything
  return null;
};

export default RouteTracker; 