/**
 * Service for Google Tag Manager dataLayer interactions
 */
class GTMService {
  /**
   * Push an event to the dataLayer
   * @param eventName The name of the event
   * @param eventParams Additional parameters for the event
   */
  pushEvent(eventName: string, eventParams?: Record<string, any>) {
    if (typeof window === 'undefined' || !window.dataLayer) {
      console.warn('DataLayer not initialized');
      return;
    }
    
    window.dataLayer.push({
      event: eventName,
      ...eventParams
    });
    
    if (process.env.NODE_ENV === 'development') {
      console.log(`[GTM] Event: ${eventName}`, eventParams);
    }
  }
  
  /**
   * Track a page view
   * @param pagePath The path of the page
   */
  trackPageView(pagePath: string) {
    this.pushEvent('page_view', {
      page_path: pagePath,
      page_location: window.location.href,
      page_title: document.title
    });
  }
  
  /**
   * Track a user login
   * @param method The login method used (email, google, etc.)
   */
  trackLogin(method: string) {
    this.pushEvent('login', { method });
  }
  
  /**
   * Track when a user views content
   * @param contentType The type of content viewed
   * @param contentId The ID of the content
   */
  trackContentView(contentType: string, contentId: string) {
    this.pushEvent('content_view', {
      content_type: contentType,
      content_id: contentId
    });
  }
  
  /**
   * Track when a user interacts with a feature
   * @param featureName The name of the feature
   * @param action The action taken
   */
  trackFeatureUse(featureName: string, action: string) {
    this.pushEvent('feature_use', {
      feature_name: featureName,
      action
    });
  }
  
  /**
   * Track errors that occur in the application
   * @param source The source of the error
   * @param message The error message
   * @param additionalInfo Additional error details
   */
  trackError(source: string, message: string, additionalInfo?: Record<string, any>) {
    this.pushEvent('error', {
      error_source: source,
      error_message: message,
      ...additionalInfo
    });
  }
}

const gtmService = new GTMService();
export default gtmService; 