import firebaseService from '../firebase/firebase.service';

class AnalyticsService {
  // Track page views when routes change
  trackPageView(pagePath: string) {
    firebaseService.trackEvent('page_view', {
      page_path: pagePath,
      page_location: window.location.href,
      page_title: document.title
    });
  }
  
  // Track when a video is successfully generated
  trackVideoCreated(params: {
    type: string;
    has_images?: boolean;
    has_logo?: boolean;
    auto_images?: boolean;
  }) {
    firebaseService.trackEvent('video_created', params);
  }
  
  // Track when a user clicks download or export
  trackDownloadClicked(params: {
    video_id: string;
    video_type?: string;
  }) {
    firebaseService.trackEvent('download_clicked', params);
  }
  
  // Track successful logins
  trackLogin(params: {
    method: string; // 'google', 'email', etc.
  }) {
    firebaseService.trackEvent('login', params);
  }
  
  // Track errors that occur in the application
  trackError(params: {
    error_source: string;
    error_message: string;
    [key: string]: any; // Additional error details
  }) {
    firebaseService.trackEvent('error_occurred', params);
  }
  
  // Track when a user upgrades their plan
  trackSubscription(params: {
    plan_name: string;
    previous_plan?: string;
    payment_type?: string; // 'monthly', 'yearly'
  }) {
    firebaseService.trackEvent('subscription', params);
  }
}

const analyticsService = new AnalyticsService();
export default analyticsService; 