import { Box } from "@components/customized"
import IScreen from "./interfaces"
import Text from "@components/controllers/text/text.component"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import Breadcrumbs from "./bread-crumbs"
import ScrollToTop from "@router/scroll-to-top"
import { trackError } from "@utils/error-tracking"

// Error fallback component that reports to analytics
const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  // Track the error in analytics
  trackError('BaseScreen', error.message, { stack: error.stack });
  
  return (
    <Box className="p-4 bg-red-50 border border-red-200 rounded-md">
      <Text type="header6" weight="bold" className="text-red-600 mb-2">
        Something went wrong
      </Text>
      <Text type="text2" className="text-red-600 mb-4">
        We've recorded this error and our team will look into it.
      </Text>
      <button
        onClick={resetErrorBoundary}
        className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
      >
        Try again
      </button>
    </Box>
  );
};

const Screen: React.FC<IScreen> = (props) => {
  const { children, disableBreadcrumbs, subTitle, title = "", ...rest } = props

  const container = document.querySelector("#main-container")
  ScrollToTop(container)

  return (
    <Box
      {...rest}
      className="relative flex 
      h-full 
      animate-fade-out
      flex-col
      gap-y-2
      bg-[var(--color-light-gray)] 
      p-3 

      "
    >
      <Box className="flex flex-col">
        {!disableBreadcrumbs && <Breadcrumbs />}
        {title && (
          <Text
            type="header6"
            weight="bold"
            className="leading-tight text-[var(--color-primary)]"
          >
            {title}
          </Text>
        )}
        {subTitle && (
          <Text type="text2" className="leading-none">
            {subTitle}
          </Text>
        )}
      </Box>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </Box>
  )
}

export default Screen
