import { observer } from "mobx-react-lite"
import { appRouter } from "@router/router"
import { RouterProvider } from "react-router-dom"
import { ConfigProvider } from "antd"
import { themeConfiguration } from "@utils/styles/theme"
// import { useEffect } from "react"
// import { initFacebookPixel } from "services/facebook-pexil/facebookPexil"
function App() {
  // useEffect(() => {
  //   initFacebookPixel("809303560988570")
  // }, [])
  return (
    <ConfigProvider theme={themeConfiguration}>
      <RouterProvider router={appRouter} />
    </ConfigProvider>
  )
}

export default observer(App)
