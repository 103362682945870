import { useEffect } from 'react';
import Env from '@utils/env';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface GoogleTagManagerProps {
  gtmId?: string;
}

/**
 * Component to initialize Google Tag Manager
 * Place this component at the root of your application
 */
const GoogleTagManager = ({ gtmId }: GoogleTagManagerProps) => {
  const tagManagerId = gtmId || Env.get('REACT_APP_GTM_ID');
  
  useEffect(() => {
    if (!tagManagerId) {
      console.warn('GTM ID not provided');
      return;
    }

    // Initialize the data layer
    window.dataLayer = window.dataLayer || [];
    
    // Push the GTM script to the page
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${tagManagerId}');
    `;
    document.head.appendChild(script);
    
    // Add the noscript iframe
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${tagManagerId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
    
    return () => {
      // Clean up
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [tagManagerId]);
  
  // This component doesn't render anything visible
  return null;
};

export default GoogleTagManager; 