import { planLevels } from "../interfaces"
import { useCurrentUser } from "./useCurrentUser"
import { useNetworkService } from "./useNetworkService"

interface UsePaymentLinkProps {
  currentPlan: string
  isYearly: boolean
}

export const usePaymentLink = ({ currentPlan, isYearly }: UsePaymentLinkProps) => {
  const { currentUser } = useCurrentUser()
  const { getPaymentLink } = useNetworkService()

  const handleSelectPlan = async (planName: string) => {
    const currentLevel = planLevels[currentPlan as keyof typeof planLevels]
    const selectedLevel = planLevels[planName.split(" ")[0].toLowerCase() as keyof typeof planLevels]

    if (selectedLevel <= currentLevel) {
      return // Can't downgrade or select current plan
    }


    const url = await getPaymentLink({
      plan: planName.split(" ")[0].toLowerCase(),
      monthly: !isYearly,
      yearly: isYearly,
      userEmail: currentUser?.email || null
    })

    if (url) {
      window.location.href = url
    }
  }

  return {
    handleSelectPlan
  }
} 