export enum Tools {
  Generator = "generator",
  Scaler = "scaler",
  Translator = "translator",
  Order = "order",
}

export enum VideoPartType {
  Story = "Story",
  CTA = "CTA",
  Hook = "Hook",
  Footage = "Footage",
  "TikTok Comment" = "TikTok Comment",
}

export enum AppearanceToEnum {
  All = "all",
  Generator = "generator",
  AI = "ai",
}

export type PlanType = 'Free' | 'Basic' | 'Premium' | 'Grow';