import { routes } from "@router/router"
import userStore from "@stores/user/user.store"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import firebaseService from "services/firebase/firebase.service"

export const useLogout = () => {
  // const { shutdown } = useIntercom()
  const navigate = useNavigate()
  return useCallback(async () => {
    await firebaseService.getAuth().signOut()
    userStore.setUserLoggedIn(false)
    // shutdown()
    navigate(routes.login)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
