import { makeAutoObservable, runInAction } from "mobx"
import INotification from "../../components/customized/notification/interfaces"
import { IMusicData } from "services/api/REST/media/media-api.interfaces"
import { Categories, Creator, CreatorName } from "services/api/REST/common"
import React from "react"

class AppStore {
  public notification: INotification = { title: "", desc: "", component: null }
  public musicList: IMusicData[] = []
  public categories: Categories = []
  public creators: Creator[] = []
  public creatorsNames: CreatorName[] = []
  public loading: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setNotify(notification: INotification) {
    runInAction(() => {
      this.notification = notification
    })
  }

  setIsLoading(isLoading: boolean) {
    runInAction(() => {
      this.loading = isLoading
    })
  }

  setMusicList(musicList: IMusicData[] = []) {
    this.musicList = musicList
  }

  setCategories(categories: Categories = []) {
    this.categories = categories
  }

  setCreators(creators: Creator[] = []) {
    this.creators = creators
  }

  setCreatorsNames(creatorsNames: Creator[] = []) {
    this.creatorsNames = creatorsNames
  }
}

const appStore = new AppStore()
export default appStore
