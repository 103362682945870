import networkService from "services/network/network.service"

interface PaymentLinkRequest {
  plan: string
  monthly: boolean
  yearly: boolean
  userEmail: string | null
}

interface PaymentLinkResponse {
  data: {
    url: string
  }
}

export const useNetworkService = () => {
  const getPaymentLink = async (request: PaymentLinkRequest): Promise<string | null> => {
    try {
      const response = await networkService.axios.post<PaymentLinkResponse>("/stripe/payment-links", request)
      return response.data?.data?.url || null
    } catch (error) {
      console.error("Error fetching payment link:", error)
      return null
    }
  }

  return {
    getPaymentLink
  }
} 