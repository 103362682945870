import analyticsService from 'services/analytics/analytics.service';
import gtmService from 'services/analytics/gtm.service';

/**
 * Tracks application errors in Firebase Analytics and Google Tag Manager
 * 
 * @param errorSource - The source/component/location where the error occurred
 * @param errorMessage - The error message
 * @param errorDetails - Optional additional error details
 */
export const trackError = (
  errorSource: string, 
  errorMessage: string, 
  errorDetails?: Record<string, any>
) => {
  console.error(`Error in ${errorSource}:`, errorMessage, errorDetails);
  
  // Track in Firebase Analytics
  analyticsService.trackError({
    error_source: errorSource,
    error_message: errorMessage,
    ...(errorDetails || {})
  });
  
  // Track in Google Tag Manager
  gtmService.trackError(errorSource, errorMessage, errorDetails);
};

/**
 * Creates an error handler function for try/catch blocks.
 * 
 * @param errorSource - The source/component where the error occurred
 * @returns A function to use in catch blocks
 * 
 * @example
 * ```
 * const handleError = createErrorHandler('AuthLogin');
 * try {
 *   // code that might throw
 * } catch (error) {
 *   handleError(error);
 * }
 * ```
 */
export const createErrorHandler = (errorSource: string) => {
  return (error: unknown) => {
    const errorMessage = error instanceof Error 
      ? error.message 
      : (typeof error === 'string' ? error : 'Unknown error');
    
    const errorDetails = error instanceof Error
      ? { stack: error.stack } 
      : (typeof error === 'object' && error !== null ? error : {});
    
    trackError(errorSource, errorMessage, errorDetails as Record<string, any>);
    return errorMessage;
  };
}; 