import { useState, useEffect } from "react"
import { User } from "firebase/auth"
import FirebaseService from "services/firebase/firebase.service"
import networkService from "services/network/network.service"

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  useEffect(() => {
    const auth = FirebaseService.getAuth()
    const unsubscribe = FirebaseService.onAuthStateChanged(
      auth,
      async (user: User | null) => {
        if (user) {
          try {
            const token = await user.getIdToken()
            networkService.setToken(token)
            setCurrentUser(user)
          } catch (error) {
            console.error("Error getting token:", error)
            setCurrentUser(null)
          }
        } else {
          networkService.setToken("")
          setCurrentUser(null)
        }
      },
      (error) => {
        console.error("Auth state change error:", error)
        setCurrentUser(null)
      }
    )

    return () => unsubscribe()
  }, [])

  return {
    currentUser
  }
} 