import userStore from "@stores/user/user.store"
import { PlanType } from "@utils/types/common.types"

function usePlanType() {
  const planType: PlanType =
    (userStore.plan?.plan?.planName || 'Free') as PlanType

  return planType
}

export default usePlanType
