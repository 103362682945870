import * as Firebase from "@firebase/auth"
import Env from "@utils/env"
import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"

class FirebaseService {
  public app: any = undefined
  public analytics: any = undefined

  constructor() {
    this.app = initializeApp({
      apiKey: Env.get("REACT_APP_FIREBASE_API_KEY"),
      authDomain: Env.get("REACT_APP_FIREBASE_AUTH_DOMAIN"),
      projectId: Env.get("REACT_APP_FIREBASE_PROJECT_ID"),
      storageBucket: Env.get("REACT_APP_FIREBASE_STORAGE_BUCKET"),
      messagingSenderId: Env.get("REACT_APP_FIREBASE_MESSAGING_SENDER_ID"),
      appId: Env.get("REACT_APP_FIREBASE_APP_ID"),
    })
    
    // Only initialize analytics in browser environment
    if (typeof window !== 'undefined') {
      this.analytics = getAnalytics(this.app);
    }

    this.GoogleAuthProvider.setCustomParameters({
      prompt: "select_account",
    })
  }

  // Track events in Firebase Analytics
  public trackEvent(eventName: string, eventParams?: Record<string, any>) {
    if (this.analytics) {
      logEvent(this.analytics, eventName, eventParams);
      console.log(`Analytics event tracked: ${eventName}`, eventParams);
    }
  }

  public FacebookAuthProvider = Firebase.FacebookAuthProvider
  public GoogleAuthProvider = new Firebase.GoogleAuthProvider()
  public OAuthProvider = Firebase.OAuthProvider
  public getAuth = Firebase.getAuth
  public signInWithEmailAndPassword = Firebase.signInWithEmailAndPassword
  public signInWithCredential = Firebase.signInWithCredential
  public createUserWithEmailAndPassword =
    Firebase.createUserWithEmailAndPassword
  public sendPasswordResetEmail = Firebase.sendPasswordResetEmail
  public onAuthStateChanged = Firebase.onAuthStateChanged
}

const firebaseService = new FirebaseService();
export default firebaseService;
