import { Button, Card, Col, Row, Typography, Switch, Tag, Divider } from "antd"
import { observer } from "mobx-react-lite"
import { CheckOutlined, CloseOutlined, ArrowLeftOutlined, CrownOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import userStore from "@stores/user/user.store"
import { PlanFeature, features, planLevels, plans } from "./interfaces"
import { useState } from "react"
import { Icons } from "@components/customized/icons"
import UserDropdown from "@components/customized/layout/navbar/user-dropdown"
import { usePaymentLink } from "./hooks/usePaymentLink"

const { Title, Text } = Typography

const PricingScreen = () => {
  const navigate = useNavigate()
 
  const currentPlan = userStore.plan?.plan?.planName.split(" ")[0].toLowerCase() || 'free'
  const [isYearly, setIsYearly] = useState(false) // State to toggle between monthly and yearly pricing
 
  const handleGoBack = () => {
    navigate(-1);
  };

  const { handleSelectPlan } = usePaymentLink({ currentPlan, isYearly })

  const getButtonProps = (planName: string) => {
    const currentLevel = planLevels[currentPlan as keyof typeof planLevels]
    const selectedLevel = planLevels[planName.split(" ")[0].toLowerCase() as keyof typeof planLevels]

    let buttonProps = {
      children: "Current Plan",
      disabled: true
    }

    if (currentLevel !== selectedLevel) {
      buttonProps = {
        children: currentLevel === planLevels.free ? "Select Plan" : "Upgrade",
        disabled: false
      }
    }

    if (selectedLevel < currentLevel) {
      buttonProps = {
        children: "Downgrade Not Available",
        disabled: true
      }
    }

    return buttonProps
  }

  const getPlanPrice = (plan: any) => {
    // Return yearly or monthly price based on the toggle, ensuring it's a whole number
    const price = isYearly ? plan.yearlyPrice : plan.price;
    return Math.round(price);
  }

  // Function to determine card background color based on plan
  const getCardStyle = (planName: string) => {
    const name = planName.toLowerCase();
    if (name === "premium package") {
      return {
        headingBg: "bg-blue-50",
        borderColor: "border-blue-500",
        shadow: "shadow-lg shadow-blue-100",
        hoverShadow: "hover:shadow-xl hover:shadow-blue-200",
        buttonType: "primary",
        ribbon: true
      };
    } else if (name === "grow package") {
      return {
        headingBg: "bg-purple-50",
        borderColor: "border-purple-400",
        shadow: "shadow-md shadow-purple-50",
        hoverShadow: "hover:shadow-lg hover:shadow-purple-100",
        buttonType: "default",
        ribbon: false
      };
    } else if (name === "basic package") {
      return {
        headingBg: "bg-green-50",
        borderColor: "border-green-400",
        shadow: "shadow-md shadow-green-50",
        hoverShadow: "hover:shadow-lg hover:shadow-green-100",
        buttonType: "default",
        ribbon: false
      };
    } else {
      // Free plan
      return {
        headingBg: "bg-gray-50",
        borderColor: "border-gray-300",
        shadow: "shadow-sm",
        hoverShadow: "hover:shadow-md",
        buttonType: "dashed",
        ribbon: false
      };
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header/Navbar styled to match other pages */}
      <nav
        className="z-10 flex h-[var(--nav-height, 64px)] items-center 
        justify-between border-b border-[var(--color-gray)] 
        px-3 py-3 lg:px-8 bg-white sticky top-0"
      >
        <div className="flex items-center">
          <Button 
            type="text" 
            icon={<ArrowLeftOutlined className="text-[18px]" />} 
            onClick={handleGoBack}
            className="mr-4 h-9 w-9 flex items-center justify-center p-0"
            aria-label="Go back"
          />
          <div 
            className="cursor-pointer" 
            onClick={() => window.open("https://www.letstok.com/", "_blank", "noopener,noreferrer")}
          >
            <Icons.LetstokBlack />
          </div>
        </div>
        <UserDropdown />
      </nav>

      {/* Main Content */}
      <div className="flex-grow bg-white">
        <div className="max-w-7xl mx-auto px-4 py-10">
          <div className="text-center mb-12">
            <Title level={2} className="mb-4">Choose Your Plan</Title>
            <Text type="secondary" className="text-lg block mb-6">
              Note: These plans are intended for personal and small business use only. For Agencies, Publishers, Enterprises, or API access, please view our <a
                href="https://www.letstok.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-600"
              >
                advanced pricing plans
              </a> or <a
                href="https://www.letstok.com/contact-us#book"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-600"
              >
                book a demo
              </a>
            </Text>
            <div className="flex items-center justify-center gap-3 bg-gray-50 p-4 rounded-lg inline-block shadow-sm">
              <Text className="text-base">Pay Monthly</Text>
              <Switch
                checked={isYearly}
                onChange={(checked) => setIsYearly(checked)}
                className={isYearly ? "bg-blue-500" : "bg-gray-300"}
              />
              <div className="flex items-center gap-2">
                <Text className="text-base">Pay Yearly</Text>
                <Tag color="green" className="animate-pulse">Save 25-30%</Tag>
              </div>
            </div>
          </div>

          <Row gutter={[24, 24]} className="mb-10">
            {plans.map((plan) => {
              const cardStyle = getCardStyle(plan.name);
              const planNameLower = plan.name.toLowerCase().split(" ")[0];
              return (
                <Col xs={24} sm={12} lg={6} key={plan.name}>
                  <div className={`relative h-full ${plan.name.toLowerCase() === "premium package" ? "transform scale-105 z-10" : ""}`}>
                    {cardStyle.ribbon && (
                      <div className="absolute -top-3 -right-3 bg-blue-500 text-white px-3 py-1 rounded-lg shadow-md z-10 rotate-12 transform">
                        <div className="flex items-center gap-1 font-medium">
                          <CrownOutlined /> Most Popular
                        </div>
                      </div>
                    )}
                    <Card 
                      className={`h-full flex flex-col transition-all duration-300 
                        hover:transform hover:-translate-y-2 ${cardStyle.hoverShadow}
                        ${cardStyle.borderColor} ${cardStyle.shadow} overflow-hidden`}
                    >
                      <div className="flex flex-col h-full justify-between">
                        {/* Header section */}
                        <div>
                          <div className={`text-center mb-6 -mx-6 -mt-6 py-6 ${cardStyle.headingBg}`}>
                            <Title level={4} className="mb-2">{plan.name}</Title>
                            {plan.name.toLowerCase() === "premium package" && (
                              <Tag color="blue" className="mb-2">Recommended</Tag>
                            )}
                          </div>

                          <div className="flex items-baseline justify-center mb-8">
                            <Text className="text-2xl mr-1">$</Text>
                            <Text className="text-5xl font-bold">{getPlanPrice(plan)}</Text>
                            <Text className="text-base ml-1 text-gray-500">
                              {isYearly ? "/year" : "/month"}
                            </Text>
                          </div>

                          <Divider className="my-6" />

                          {/* Plan stats section - fixed height for alignment */}
                          <div className="mb-4">
                            <div className="flex items-center gap-2 mb-2">
                              <Text className="font-medium">{plan.interactions} interactions/day</Text>
                            </div>
                            <div className="flex items-center gap-2">
                              <Text className="font-medium">{plan.videos} Generated Videos/Month</Text>
                            </div>
                          </div>

                          {/* Features section - scrollable if needed with min-height */}
                          <div className="space-y-4 overflow-auto min-h-[300px]">
                            {features.map((feature) => {
                              // Skip the 1-month license feature for all except the Free plan
                              if (feature.name === "1 month non-renewable commercial license for generated video ads" && 
                                  planNameLower !== "free") {
                                return null;
                              }
                              
                              return (
                                <div key={feature.name} className="flex items-start gap-2">
                                  {feature[planNameLower as keyof PlanFeature] ? (
                                    <CheckOutlined className="text-green-500 mt-1" />
                                  ) : (
                                    <CloseOutlined className="text-red-500 mt-1" />
                                  )}
                                  <div className="flex flex-col gap-1">
                                    <div className="flex items-center gap-2">
                                      <Text className="text-sm">{feature.name}</Text>
                                      {feature.name === "Chat-based video editor" && planNameLower !== "free" && (
                                        <Tag color="blue" className="text-xs">BETA</Tag>
                                      )}
                                      {feature.name === "ROAS Insights" && (
                                        <Tag color="blue" className="text-xs">BETA</Tag>
                                      )}
                                    </div>
                                    {planNameLower === "grow" && feature.name === "API Connections" && (
                                      <Tag color="green" className="text-xs">SMB license - pay as you go</Tag>
                                    )}
                                  </div>
                                </div>
                              );
                            })}

                            <div className="flex items-center gap-2 mt-4">
                              <Text className="font-medium">${plan.pricePerVideo} per Downloaded Video</Text>
                            </div>
                            
                            {/* Add spacers with different heights for different plans */}
                            {planNameLower === "basic" && (
                              <div className="h-12"></div>
                            )}
                            {planNameLower === "premium" && (
                              <div className="h-12"></div>
                            )}
                            {planNameLower === "grow" && (
                              <div className="h-6"></div>
                            )}
                          </div>
                        </div>

                        {/* Button section - always at the bottom */}
                        <div className="mt-8">
                          <Button
                            type={plan.name.toLowerCase() === "premium package" ? "primary" : cardStyle.buttonType as any}
                            block
                            onClick={() => handleSelectPlan(plan.name)}
                            className={`h-10 ${plan.name.toLowerCase() === "premium package" ? "bg-blue-500 hover:bg-blue-600" : ""}`}
                            {...getButtonProps(plan.name)}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              );
            })}
          </Row>

          <div className="bg-gray-50 p-8 rounded-lg text-center mt-12 shadow-sm">
            <Text type="secondary" className="block text-base">
              <div className="font-medium mb-2">Disclaimer:</div>
              <div className="mb-2">
                The plans above are intended for personal or single-business (SMB) use only, and are limited to one seat per user.
              </div>
              <div className="mb-2">
                Use of Letstok-generated videos for any online or commercial distribution (including websites, social media, or paid ads) requires an active paid plan.
              </div>
              <div className="mb-2">
                These plans do not permit reselling, agency services, publisher or search arbitrage use, enterprise deployment, technology partnerships, or API-based automation.
              </div>
              <div>
                For full licensing details, please refer to our <a
                  href="https://www.letstok.com/msa-subscription"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline hover:text-blue-600"
                >
                  Terms of Service
                </a>, or <a
                  href="https://www.letstok.com/pricing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline hover:text-blue-600"
                >
                  click here
                </a> to explore plans for Agencies, Enterprises, and API integrations.
              </div>
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PricingScreen)